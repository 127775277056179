let m = require('mithril');

module.exports = {
    view: function(vnode) {
	return m('header', [
	    m(m.route.Link, { href: '/' }, m('h1', vnode.attrs.title)),
	    (vnode.attrs.sub) ? vnode.attrs.sub.map((sub) => {return sub}) : null
	])
    }
}
