let m = require("mithril");

let Header = require("./views/vHeader");
let Nav = require("./views/vNav");
let PostList = require("./views/vPostList");
let Post = require("./views/vPostContent");
let Footer = require("./views/vFooter");

let root = document.body;

import './style.scss';

if (process.env.NODE_ENV !== 'production') {
    console.log('We\'re in dev mode, sugar.');
}

m.route(root, '/', {
    '/': {		
	render: (vnode) => {
	    return [
		m(Header, {
		    title: 'Stone Throne',
		    sub: [
			m(Nav, { filter: { tags: ['STZ'] }})
		    ]}),
		m('section.main', m(PostList, { page: 1, filter: { tags: ['STZ'] }})),
		m(Footer)
	    ]
	}
    },

    '/:page': {		
	render: (vnode) => {
	    return [
		m(Header, {
		    title: 'Stone Throne',
		    sub: [
			m(Nav, { filter: { tags: ['STZ'] }})
		    ]}),
		m('section.main', m(PostList, { page: vnode.attrs.page, filter: { tags: ['STZ'] }})),
		m(Footer)
	    ]
	}
    },

    '/post/:slug': {
	render: (vnode) => {
	    return [
		m(Header, {
		    title: 'Stone Throne',
		    sub: [
			m(Nav, { filter: { tags: ['STZ'] }})
		    ]}),
		m('section.main', m(Post, { slug: vnode.attrs.slug })),
		m(Footer)
	    ]
	}
    },

    '/page/:slug': {
	render: (vnode) => {
	    return [
		m(Header, {
		    title: 'Stone Throne',
		    sub: [
			m(Nav, { filter: { tags: ['STZ'] }})
		    ]}),
		m('section.main', [
		    m(Post, { page_slug: vnode.attrs.slug })
		]),
		m(Footer)
	    ]
	}
    }
});
