let m = require('mithril');

let Post = require('../views/vPostContent');

let mPost = require('../models/mPost');

module.exports = {
    oninit: function(vnode) {
	mPost.loadList(vnode.attrs.page, vnode.attrs.filter);
    },

    onupdate: function(vnode) {
	if (vnode.attrs.page && (mPost.pagination.page != vnode.attrs.page))
	{
	    mPost.pagination.page = vnode.attrs.page;
	    mPost.loadList(vnode.attrs.page, vnode.attrs.filter);
	}
    },
    
    view: function(vnode) {
	return mPost.list.map((post) => {
	    return m(Post, { post: post })
	});
    }
}
