let m = require("mithril");

function buildFilter(filter) {
    if (!filter)
	return ''
    
    let oFilter = ''    

    if ('tags' in filter) {
	oFilter += 'tags.name:[' + filter.tags.join('+') + ']';
    }

    if (oFilter != '')
	oFilter = '&filter=' + oFilter;

    return oFilter
}

let mPage = {
    list: [],
    
    loadList: (filter) => {
	return m.request({
	    method: "GET",
	    url: `${API_BASE}/content/pages/?key=${API_KEY}` + buildFilter(filter)
	}).then((result) => {	    
	    mPage.list = result.pages;
	})
    },

    current: {},
    
    load: (slug) => {	
	return m.request({
	    method: "GET",
	    url: `${API_BASE}/content/pages/slug/${slug}/?key=${API_KEY}`
	}).then((result) => {
	    mPage.current = result.pages[0];
	})
    }
}

module.exports = mPage;
